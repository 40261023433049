import { useMemo } from 'react';

import { routes } from 'constants/routing';
import { useAuth } from 'context/AuthContext';

import { MenuItem } from '../types';

const useGetMenuItems = () => {
  const { accountAddress } = useAuth();

  return useMemo(() => {
    const menuItems: MenuItem[] = [
      {
        href: routes.dashboard.path,
        // Translation key: do not remove this comment
        // t('layout.menuItems.dashboard')
        i18nKey: 'layout.menuItems.dashboard',
        icon: 'dashboard',
      },
    ];

    return menuItems;
  }, [accountAddress]);
};

export default useGetMenuItems;
