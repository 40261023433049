import { MutationObserverOptions, useMutation } from 'react-query';

import queryClient from 'clients/api/queryClient';
import { useIfoContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { useAuth } from 'context/AuthContext';

import claim, { ClaimFromIfoInput, ClaimFromIfoOutput } from '.';

const claimFromIfo = (
  options?: MutationObserverOptions<
    ClaimFromIfoOutput,
    Error,
    Omit<ClaimFromIfoInput, 'ifoContract'>
  >,
) => {
  const { accountAddress } = useAuth();
  const ifoContract = useIfoContract();

  return useMutation(
    [FunctionKey.CLAIM_FROM_IFO, accountAddress, ifoContract.address],
    params => claim({ ifoContract, ...params }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        queryClient.invalidateQueries([FunctionKey.GET_IFO_DETAILS, accountAddress]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default claimFromIfo;
