import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      margin: 5rem 0;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-around;
      gap: ${theme.spacing(8)};
    `,
    card: css`
      display: flex;
      width: 400px;
      flex-direction: column;
      border: 1px solid ${theme.palette.secondary.light};
    `,
    cardHeader: css`
      padding: ${theme.spacing(4)};
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${theme.palette.background.default};
    `,
    cardInfo: css`
      display: flex;
      padding: ${theme.spacing(4)};
      gap: ${theme.spacing(4)};
    `,
    cardContent: css`
      display: flex;
      flex-direction: column;
    `,
    cardDetails: css`
      padding: ${theme.spacing(4)};
      background-color: ${theme.palette.background.default};
    `,
  };
};
