/** @jsxImportSource @emotion/react */
import { Provider } from '@ethersproject/providers';
import { Link, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as LocalLink } from 'react-router-dom';

import { ReactComponent as LogoDiscord } from 'assets/img/discord.svg';
import { ReactComponent as LogoEth } from 'assets/img/tokens/eth.svg';
import { ReactComponent as LogoTwitter } from 'assets/img/twitter.svg';
import brand from 'config/brand';
import { useAuth } from 'context/AuthContext';

import ConnectButton from '../ConnectButton';
import { Toolbar } from '../Toolbar';
// import Breadcrumbs from './Breadcrumbs';
import { useStyles } from './styles';

const getChainLinkPricefeed = (provider: Provider) =>
  new ethers.Contract(
    '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
    [
      {
        inputs: [],
        name: 'latestAnswer',
        outputs: [{ internalType: 'int256', name: '', type: 'int256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    provider,
  );

const Header: React.FC = () => {
  const styles = useStyles();

  const { provider } = useAuth();
  const priceFeedContract = getChainLinkPricefeed(provider);
  const { data, error } = useQuery(
    ['eth-feed-price'],
    async () => {
      const ethPrice = await priceFeedContract.latestAnswer();
      return new BigNumber(ethPrice.toString()).div(1e8).toFixed(2);
    },
    {
      refetchInterval: 2500,
    },
  );

  return (
    <AppBar position="relative" css={styles.appBar}>
      <Toolbar css={styles.toolbar}>
        {/* <Breadcrumbs /> */}

        <div css={styles.justifiedSpace}>
          <LocalLink to="/" css={{ display: 'grid', placeContent: 'center' }}>
            <img src={brand.logoUrlWithText} alt="" />
          </LocalLink>
          <Link target="_blank" title="Twitter" href={brand.social.twitter} css={styles.socialIcon}>
            <LogoTwitter />
          </Link>
          <Link target="_blank" title="Discord" href={brand.social.discord} css={styles.socialIcon}>
            <LogoDiscord />
          </Link>
        </div>

        <div css={styles.justifiedSpace}>
          <Typography
            title="Source: Chainlink"
            variant="h2"
            fontSize={16}
            css={[styles.justifiedSpace, styles.ethPrice]}
          >
            <LogoEth /> {brand.currencySymbol} / USD - ${!data || error ? '-.--' : data}
          </Typography>
          <ConnectButton />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
