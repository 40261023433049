import BigNumber from 'bignumber.js';
import { Multicall } from 'ethereum-multicall';

import IFO_ABI from 'constants/contracts/abis/ifo.json';
import { Ifo } from 'types/contracts';

export interface GetIfoDetailsInput {
  ifoContract: Ifo;
  multicall: Multicall;
  accountAddress: string;
}

export interface GetIfoDetailsOutput {
  startTime: number;
  endTime: number;
  toRaise: BigNumber;
  distributionAmount: BigNumber;
  depositLimit: BigNumber;
  totalRaised: BigNumber;
  whitelistOnly: boolean;
  userInfo?: UserInfo;
}

export interface UserInfo {
  allocation: BigNumber;
  contribution: BigNumber;
  whitelisted: boolean;
  whitelistCap: BigNumber;
  discount: BigNumber;
  discountEligibleAmount: BigNumber;
  hasClaimed: boolean;
  claimAmount: BigNumber;
}

const getIfoDetails = async ({
  ifoContract,
  multicall,
  accountAddress,
}: GetIfoDetailsInput): Promise<GetIfoDetailsOutput | undefined> => {
  try {
    const { results } = await multicall.call([
      {
        abi: IFO_ABI,
        reference: 'ifoDetails',
        contractAddress: ifoContract.address,
        calls: [
          {
            reference: 'startTime',
            methodName: 'START_TIME',
            methodParameters: [],
          },
          {
            reference: 'endTime',
            methodName: 'END_TIME',
            methodParameters: [],
          },
          {
            reference: 'distributionAmount',
            methodName: 'MAX_PROJECT_TOKENS_TO_DISTRIBUTE',
            methodParameters: [],
          },
          {
            reference: 'toRaise',
            methodName: 'MIN_TOTAL_RAISED_FOR_MAX_PROJECT_TOKEN',
            methodParameters: [],
          },
          {
            reference: 'depositLimit',
            methodName: 'CAP_PER_WALLET',
            methodParameters: [],
          },
          {
            reference: 'totalRaised',
            methodName: 'totalRaised',
            methodParameters: [],
          },
          {
            reference: 'whitelistOnly',
            methodName: 'whitelistOnly',
            methodParameters: [],
          },
        ],
      },
    ]);

    const [
      startTime,
      endTime,
      distributionAmount,
      toRaise,
      depositLimit,
      totalRaised,
      whitelistOnly,
    ] = results.ifoDetails.callsReturnContext;

    const ifoData: GetIfoDetailsOutput = {
      whitelistOnly: whitelistOnly.returnValues[0],
      endTime: Number(endTime.returnValues[0].hex),
      startTime: Number(startTime.returnValues[0].hex),
      toRaise: new BigNumber(toRaise.returnValues[0].hex),
      totalRaised: new BigNumber(totalRaised.returnValues[0].hex),
      depositLimit: new BigNumber(depositLimit.returnValues[0].hex),
      distributionAmount: new BigNumber(distributionAmount.returnValues[0].hex),
    };

    if (!accountAddress) return ifoData;

    try {
      const [userInfo, claimAmounts] = await Promise.all([
        ifoContract.userInfo(accountAddress),
        ifoContract.getExpectedClaimAmount(accountAddress),
      ]);
      ifoData.userInfo = {
        allocation: new BigNumber(userInfo.allocation.toString()),
        contribution: new BigNumber(userInfo.contribution.toString()),
        discount: new BigNumber(userInfo.discount.toString()),
        discountEligibleAmount: new BigNumber(userInfo.discountEligibleAmount.toString()),
        claimAmount: new BigNumber(claimAmounts.projectTokenAmount.toString()),
        whitelistCap: new BigNumber(userInfo.whitelistCap.toString()),
        whitelisted: userInfo.whitelisted,
        hasClaimed: userInfo.hasClaimed,
      };
    } catch (err) {
      // throw new Error(`getIfoDetails:userInfo: ${err}`);
    }

    return ifoData;
  } catch (err) {
    throw new Error(`getIfoDetails: ${err}`);
  }
};

export default getIfoDetails;
