import { useQuery } from 'react-query';

import { useIfoContract } from 'clients/contracts';
import { useMulticall } from 'clients/web3';
import FunctionKey from 'constants/functionKey';
import { useAuth } from 'context/AuthContext';

import getIfoDetails from '.';

const uesIfoDetails = () => {
  const { accountAddress } = useAuth();
  const ifoContract = useIfoContract();
  const multicall = useMulticall();

  return useQuery([FunctionKey.GET_IFO_DETAILS, accountAddress, ifoContract.address], async () =>
    getIfoDetails({ ifoContract, accountAddress, multicall }),
  );
};

export default uesIfoDetails;
