import { ethers } from 'ethers';
import { BscChainId, Token } from 'types';

import eth from 'assets/img/tokens/eth.svg';
import ltoken from 'assets/img/tokens/ltoken.svg';
import TOKENS from 'constants/contracts/addresses/tokens.json';

// These are the actual underlying tokens, not the LLTokens & thus doesn't need to replaced
export const MAINNET_TOKENS = {
  depositToken: {
    address: TOKENS.depositToken[BscChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'WETH',
    asset: eth,
    isNative: false,
  } as Token,
  eth: {
    address: ethers.constants.AddressZero,
    decimals: 18,
    symbol: 'ETH',
    asset: eth,
    isNative: true,
  } as Token,
  rewardToken: {
    address: TOKENS.rewardToken[BscChainId.MAINNET].toLowerCase(),
    decimals: 18,
    symbol: 'LVoucher',
    asset: ltoken,
    isNative: false,
  } as Token,
};
