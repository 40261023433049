import { useMemo } from 'react';
import { Token } from 'types';

import { useAuth } from 'context/AuthContext';

import { getIfoContract, getTokenContract } from './getters';

export const useTokenContract = (token: Token) => {
  const { signer } = useAuth();
  return useMemo(() => getTokenContract(token, signer || undefined), [signer, token]);
};

export const useIfoContract = () => {
  const { signer } = useAuth();
  return useMemo(() => getIfoContract(signer || undefined), [signer]);
};
