import { Contract, ContractInterface, Signer } from 'ethers';
import { Token } from 'types';
import { getContractAddress } from 'utilities';

import { chain, provider } from 'clients/web3';
import erc20Abi from 'constants/contracts/abis/erc20.json';
import ifoAbi from 'constants/contracts/abis/ifo.json';
import { Ifo } from 'types/contracts';

import { TokenContract } from './types';

export const getContract = ({
  abi,
  address,
  signer,
}: {
  abi: ContractInterface;
  address: string;
  signer?: Signer;
}) => {
  const signerOrProvider = signer ?? provider({ chainId: chain.id });
  return new Contract(address, abi, signerOrProvider);
};

export const getTokenContract = (token: Token, signer?: Signer) =>
  getContract({
    abi: erc20Abi,
    address: token.address,
    signer,
  }) as TokenContract;

export const getIfoContract = (signer?: Signer) =>
  getContract({
    abi: ifoAbi,
    address: getContractAddress('ifo'),
    signer,
  }) as Ifo;
