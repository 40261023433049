import { ContractReceipt, ContractTransaction } from 'ethers';

import { Ifo } from 'types/contracts';

export interface DepositToIfoInput {
  ifoContract: Ifo;
  amount: string;
  useNative?: boolean;
}

export type DepositToIfoOutput = ContractReceipt;

const depositToIfo = async ({
  ifoContract,
  amount,
  useNative,
}: DepositToIfoInput): Promise<DepositToIfoOutput> => {
  let transaction: ContractTransaction;

  if (useNative) {
    transaction = await ifoContract.buyETH({ value: amount });
  } else {
    transaction = await ifoContract.buy(amount);
  }

  return transaction.wait(1);
};

export default depositToIfo;
