/* eslint-disable no-console */
import { Box, Input, Paper, Typography } from '@mui/material';
import { BscLink, Button, ConnectWallet, TextField } from 'components';
import config from 'config';
import { ethers } from 'ethers';
import { parseEther, parseUnits } from 'ethers/lib/utils';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Token } from 'types';

import { useGetTokenBalances } from 'clients/api';
import erc20Abi from 'constants/contracts/abis/erc20.json';
import wethAbi from 'constants/contracts/abis/weth.json';
import { MAINNET_TOKENS } from 'constants/tokens/common/mainnet';
import { useAuth } from 'context/AuthContext';
import useAddTokenToWallet from 'hooks/useAddTokenToWallet';

const tokens = [MAINNET_TOKENS.depositToken, MAINNET_TOKENS.rewardToken, MAINNET_TOKENS.eth];

const whales = {
  [MAINNET_TOKENS.depositToken.address]: '0x40093c0156cD8d1DAEFb5A5465D17FcC6467Aa31',
};

const useMintFunctionFor = [MAINNET_TOKENS.rewardToken.address];
const useDepositFunctionFor = [MAINNET_TOKENS.depositToken.address];

const Faucet = () => {
  const { accountAddress, signer } = useAuth();
  const [minutes, setMinutes] = useState<string>();
  const [inputValues, setInputValues] = useState(tokens.map(() => ''));
  const [currTimestamp, setCurrTimestamp] = useState(0);
  const { data: balances, error } = useGetTokenBalances({ accountAddress, tokens });
  const provider = useMemo(() => new ethers.providers.JsonRpcProvider(config.rpcUrl), []);

  const addTokenToWallet = useAddTokenToWallet();

  if (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed fetching balances: ${error}`);
  }

  const getBlockNumber = async () => {
    const localBlock = await provider.getBlockNumber();
    const block = await provider.getBlock(localBlock);

    setCurrTimestamp(+block.timestamp);
    return localBlock;
  };

  useEffect(() => {
    getBlockNumber();
    console.log({ tokenBalances: balances?.tokenBalances });
  }, [balances]);

  const mint =
    (token: Token, index: number): React.FormEventHandler<HTMLFormElement> =>
    async e => {
      e.preventDefault();
      try {
        if (token.address === ethers.constants.AddressZero) {
          await provider.send('hardhat_setBalance', [
            accountAddress,
            `0x${(+parseUnits(inputValues[index], token.decimals)
              .add(balances?.tokenBalances[index].balanceWei.toString() || 0)
              .toString()).toString(16)}`,
          ]);
        } else if (useMintFunctionFor.includes(token.address)) {
          const contract = new ethers.Contract(token.address, erc20Abi, signer);
          await contract.mint(accountAddress, parseUnits(inputValues[index], token.decimals));
        } else if (useDepositFunctionFor.includes(token.address)) {
          const contract = new ethers.Contract(token.address, wethAbi, signer);
          await contract.deposit({ value: parseUnits(inputValues[index], token.decimals) });
        } else {
          await provider.send('hardhat_impersonateAccount', [whales[token.address]]);
          if ((await provider.getBalance(whales[token.address])).lt(parseEther('10'))) {
            await provider.send('hardhat_setBalance', [
              whales[token.address],
              parseEther('10').toHexString(),
            ]);
          }
          const impersonatedSigner = provider.getSigner(whales[token.address]);
          const contract = new ethers.Contract(token.address, erc20Abi, impersonatedSigner);
          await contract.transfer(accountAddress, parseUnits(inputValues[index], token.decimals));
        }
        // eslint-disable-next-line no-alert
        alert(`${inputValues[index]} ${token.symbol} Minted`);
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert('Minting failed, retry with smaller amount');
        // eslint-disable-next-line no-console
        console.error('mint:', err);
      }
    };

  const travel50 = async () => {
    if (!minutes) return;

    let localBlock = await getBlockNumber();
    const blockBefore = await provider.getBlock(localBlock);
    console.log('block timestamp:before::timestamp', blockBefore.timestamp);

    await provider.send('evm_increaseTime', [+minutes * 60]);

    localBlock = await getBlockNumber();
    const localBlockAfter = await provider.getBlock(localBlock);
    console.log('timestamp difF:', Math.abs(blockBefore.timestamp - localBlockAfter.timestamp));
  };

  return (
    <Paper>
      <ConnectWallet message="Connect wallet to mint tokens">
        <Typography variant="h1">Faucet</Typography>

        <Box padding={4} display="flex" gap={4}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body2">Current time:</Typography>
            <Typography variant="body1">{moment.unix(currTimestamp).toString()}</Typography>
          </Box>

          <Box display="flex" flexDirection="row" gap={4}>
            <TextField
              value={minutes}
              onChange={({ target }) => setMinutes(target.value)}
              placeholder="Minutes to travel ahead"
            />
            <Button onClick={travel50} variant="primary" disabled={!minutes}>
              <span role="img" aria-label="rocket">
                🚀
              </span>
              Travel
            </Button>
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap" gap={4}>
          {tokens.map((token, index) => (
            <Paper
              component="form"
              style={{ padding: 16 }}
              key={token.address}
              onSubmit={mint(token, index)}
            >
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{token.symbol}</Typography>
                <BscLink hash={token.address} urlType="address" />
                <Typography>
                  Balance:{' '}
                  {balances?.tokenBalances[index]?.balanceWei
                    .dividedBy(10 ** token.decimals)
                    .toFixed(3)}{' '}
                  {token.symbol}
                </Typography>
                <Input
                  value={inputValues[index]}
                  placeholder="Amount to mint"
                  onChange={({ target }) =>
                    setInputValues(v => v.map((value, i) => (i === index ? target.value : value)))
                  }
                />
                <Box display="flex" gap={2}>
                  <Button variant="secondary" type="submit">
                    Mint
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    disabled={!token.asset}
                    onClick={() => addTokenToWallet(token)}
                  >
                    Add to Wallet
                  </Button>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      </ConnectWallet>
    </Paper>
  );
};

export default Faucet;
