/** @jsxImportSource @emotion/react */
import { Box, Divider, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  Button,
  ConnectWallet,
  EnableToken,
  InfoIcon,
  Notice,
  Spinner,
  TextField,
  Toggle,
} from 'components';
import { ethers } from 'ethers';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  convertTokensToWei,
  formatToReadablePercentage,
  formatTokensToReadableValue,
  getContractAddress,
} from 'utilities';

import { ReactComponent as TokenIcon } from 'assets/img/metamask.svg';
import { useClaimFromIfo, useDepositToIfo, useGetBalanceOf, useIfoDetails } from 'clients/api';
import { TOKENS } from 'constants/tokens';
import { useAuth } from 'context/AuthContext';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import { useStyles } from './styles';

type Countdown = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

const defaultTTL: Countdown = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
};

const getFormattedTimeString = (
  { days, hours, minutes, seconds }: Countdown,
  countingTo?: 'start' | 'end',
) =>
  countingTo
    ? `Sale will ${countingTo} in ${days}D : ${hours}H : ${minutes}M : ${seconds}S`
    : `${days}D : ${hours}H : ${minutes}M : ${seconds}S`;

const Dashboard: React.FC = () => {
  const style = useStyles();
  const theme = useTheme();
  const { accountAddress, provider } = useAuth();
  const ifoAddress = getContractAddress('ifo');
  const { data, isLoading } = useIfoDetails();
  const { mutateAsync: claim, isLoading: claiming } = useClaimFromIfo();
  const { mutateAsync: deposit, isLoading: depositing } = useDepositToIfo();
  const handleTransactionMutation = useHandleTransactionMutation();
  const { data: depositTokenBalance } = useGetBalanceOf({
    accountAddress,
    token: TOKENS.depositToken,
  });
  const { data: ethBalance } = useGetBalanceOf({
    accountAddress,
    token: TOKENS.eth,
  });

  const [currentTS, setCurrentTS] = useState<number>(Math.floor(Date.now()) / 1000);
  const [ttl, setTTL] = useState<string>(getFormattedTimeString(defaultTTL, 'start'));
  const [depositAmount, setDepositAmount] = useState<string>('');
  const [useNative, setUseNative] = useState<boolean>(false);

  const capApplicable =
    !!data?.depositLimit.gt(0) && !data.depositLimit.eq(ethers.constants.MaxUint256.toString());
  const hasEnded = data?.endTime
    ? moment.unix(data.endTime).isBefore(moment.unix(currentTS))
    : false;
  const isActive = data?.startTime
    ? moment.unix(data.startTime).isBefore(moment.unix(currentTS)) && !hasEnded
    : false;

  const maxBalance = useMemo<string>(() => {
    if (useNative) {
      return !ethBalance
        ? '---'
        : formatTokensToReadableValue({
            value: ethBalance.balanceWei.div(1e18),
            token: TOKENS.eth,
            minimizeDecimals: 2,
          });
    }
    return !depositTokenBalance
      ? '---'
      : formatTokensToReadableValue({
          value: depositTokenBalance.balanceWei.div(1e18),
          token: TOKENS.depositToken,
          minimizeDecimals: 2,
        });
  }, [useNative, ethBalance, depositTokenBalance]);

  const maxAmount = useMemo<string>(() => {
    let max = new BigNumber(0);

    if (data?.whitelistOnly && data.userInfo?.whitelisted) {
      max = data.userInfo.whitelistCap;
    } else if (capApplicable && data?.depositLimit) {
      max = data.depositLimit;
    }

    if (useNative) {
      if (ethBalance && max.gt(ethBalance.balanceWei)) {
        max = ethBalance.balanceWei;
      }
    } else if (depositTokenBalance && max.gt(depositTokenBalance.balanceWei)) {
      max = depositTokenBalance.balanceWei;
    }

    return max.div(1e18).toString();
  }, [useNative, ethBalance, depositTokenBalance, capApplicable]);

  useEffect(() => {
    if (hasEnded || !data?.startTime || !data.endTime) return;

    const time = isActive ? data.endTime : data.startTime;
    const updateTTL = () => {
      setTTL(
        getFormattedTimeString(
          {
            days: String(moment.unix(time).diff(moment.unix(currentTS), 'd')).padStart(2, '0'),
            hours: String(moment.unix(time).diff(moment.unix(currentTS), 'h') % 24).padStart(
              2,
              '0',
            ),
            minutes: String(moment.unix(time).diff(moment.unix(currentTS), 'm') % 60).padStart(
              2,
              '0',
            ),
            seconds: String(moment.unix(time).diff(moment.unix(currentTS), 's') % 60).padStart(
              2,
              '0',
            ),
          },
          isActive ? 'end' : 'start',
        ),
      );
    };

    const timer = setInterval(updateTTL, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [data?.startTime, data?.endTime, isActive, hasEnded, currentTS]);

  const transact = async () => {
    if (!depositAmount) return;

    const valueWei = convertTokensToWei({
      value: new BigNumber(depositAmount),
      token: TOKENS.depositToken,
    });

    await handleTransactionMutation({
      mutate: () =>
        deposit({
          amount: valueWei.toString(),
          useNative,
        }),
      successTransactionModalProps: contractReceipt => ({
        title: 'Your deposit was successful',
        amount: {
          valueWei,
          token: TOKENS.depositToken,
        },
        transactionHash: contractReceipt.transactionHash,
      }),
    });

    setDepositAmount('');
  };

  const handleClaim = async () => {
    if (data?.userInfo?.claimAmount.eq(0)) return;

    return handleTransactionMutation({
      mutate: () => claim({}),
      successTransactionModalProps: contractReceipt => ({
        title: 'Your claim was successful',
        amount: {
          valueWei: data?.userInfo?.claimAmount || new BigNumber(0),
          token: TOKENS.rewardToken,
        },
        transactionHash: contractReceipt.transactionHash,
      }),
    });
  };

  const getCtaParams = useCallback(
    (isTokenEnabled: boolean, enableToken: () => Promise<void>) => {
      const params = {
        disabled: false,
        action: enableToken,
        title: 'Please wait for sale to start',
      };

      if (isActive) {
        if (data?.whitelistOnly && !data.userInfo?.whitelisted) {
          params.title = 'Whitelist only sale';
          params.disabled = true;
        } else if (useNative) {
          params.title = 'Deposit ETH';
          params.action = transact;
          if (!depositAmount || !+depositAmount) {
            params.disabled = true;
          }
        } else if (isTokenEnabled) {
          params.title = `Deposit ${TOKENS.depositToken.symbol}`;
          params.action = transact;
          if (!depositAmount || !+depositAmount) {
            params.disabled = true;
          }
        } else {
          params.title = 'Enable deposit';
        }
      } else if (hasEnded) {
        if (data?.userInfo?.hasClaimed) {
          params.title = 'Share already claimed';
          params.disabled = true;
        } else if (data?.userInfo?.claimAmount.gt(0)) {
          params.title = 'Claim your share';
          params.action = handleClaim;
        } else {
          params.title = 'Sale is over';
          params.disabled = true;
        }
      } else {
        params.disabled = true;
      }

      return params;
    },
    [isActive, hasEnded, useNative, depositAmount, data, data?.userInfo],
  );

  const tooltipText = useMemo(() => {
    if (data?.whitelistOnly) {
      if (data.userInfo?.whitelisted) {
        return `You are whitelisted to deposit upto ${formatTokensToReadableValue({
          value: data.depositLimit.div(1e18),
          token: TOKENS.depositToken,
          minimizeDecimals: 2,
        })}`;
      }
      return 'Only whitelisted addresses are allowed to take part in this sale';
    }
    if (capApplicable && data?.depositLimit?.gt(0)) {
      return `Max deposit limit applicable of ${formatTokensToReadableValue({
        value: data.depositLimit.div(1e18),
        token: TOKENS.depositToken,
        minimizeDecimals: 2,
      })}`;
    }
    return 'There is no limit on amount to deposit';
  }, [data, data?.depositLimit, data?.userInfo, data?.whitelistOnly, capApplicable]);

  useEffect(() => {
    if (!data) return;
    provider.getBlock('latest').then(({ timestamp }) => {
      setCurrentTS(timestamp);
      // eslint-disable-next-line no-console
      console.debug('Time difference:', {
        onChainTS: timestamp,
        offChainTS: Math.floor(Date.now() / 1000),
        difference: getFormattedTimeString({
          days: String(moment.unix(timestamp).diff(moment(), 'd')).padStart(2, '0'),
          hours: String(moment.unix(timestamp).diff(moment(), 'h') % 24).padStart(2, '0'),
          minutes: String(moment.unix(timestamp).diff(moment(), 'm') % 60).padStart(2, '0'),
          seconds: String(moment.unix(timestamp).diff(moment(), 's') % 60).padStart(2, '0'),
        }),
        saleStartsAt: data.startTime,
        saleEndsAt: data.endTime,
      });
    });
  }, [provider, data]);

  return isLoading || !data ? (
    <Spinner />
  ) : (
    <div css={style.container}>
      <div css={style.card}>
        <div css={style.cardHeader}>
          <Typography variant="body2">Public Sale</Typography>
          <InfoIcon tooltip={tooltipText} />
        </div>
        <div css={style.cardInfo}>
          <TokenIcon width={40} />
          <div css={style.cardContent}>
            <Typography variant="small1" color={theme.palette.primary.light}>
              ON SALE
            </Typography>
            <Typography>
              {formatTokensToReadableValue({
                value: data.distributionAmount.div(1e18),
                token: TOKENS.rewardToken,
                minimizeDecimals: 2,
              })}
            </Typography>
          </div>
        </div>
        <Box paddingX={4}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">Sale starting from</Typography>
            <Typography variant="body2">Sale ends at</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">{moment.unix(data.startTime).toLocaleString()}</Typography>
            <Typography variant="body1" textAlign="right">
              {moment.unix(data.endTime).toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <Box padding={4} display="flex" flexDirection="column" gap={4}>
          {!accountAddress ? (
            <ConnectWallet />
          ) : (
            <EnableToken
              token={TOKENS.depositToken}
              spenderAddress={ifoAddress}
              title="Enable Deposit"
              noUi
            >
              {({ enableToken, isTokenEnabled, isEnableTokenLoading }) => {
                const { action, disabled, title } = getCtaParams(isTokenEnabled, enableToken);
                return (
                  <>
                    {!hasEnded && <Notice description={ttl} />}
                    {isActive && (!data.whitelistOnly || data.userInfo?.whitelisted) && (
                      <>
                        {(useNative || isTokenEnabled) && (
                          <TextField
                            css={{ width: '100%' }}
                            label={
                              <Box display="flex" justifyContent="space-between" marginBottom={2}>
                                <Typography variant="small2">Enter Amount</Typography>
                                <Typography variant="small1">Balance: {maxBalance}</Typography>
                              </Box>
                            }
                            max={maxAmount}
                            value={depositAmount}
                            onChange={({ target }) => setDepositAmount(target.value)}
                            rightAdornment={
                              <Button small onClick={() => setDepositAmount(maxAmount)}>
                                MAX
                              </Button>
                            }
                          />
                        )}
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="body1">Use Native ETH</Typography>
                          <Toggle
                            value={useNative}
                            css={{ justifySelf: 'flex-end' }}
                            onChange={(_, value) => setUseNative(value)}
                            isLight
                          />
                        </Box>
                      </>
                    )}
                    <Button
                      fullWidth
                      onClick={action}
                      disabled={depositing || isEnableTokenLoading || disabled}
                      loading={isEnableTokenLoading || depositing || claiming}
                    >
                      {title}
                    </Button>
                  </>
                );
              }}
            </EnableToken>
          )}
        </Box>
        <div css={style.cardDetails}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Funds to Raise</Typography>
            <Typography>
              {formatTokensToReadableValue({
                value: data.toRaise.div(1e18),
                token: TOKENS.depositToken,
                minimizeDecimals: 2,
              })}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Price per {TOKENS.depositToken.symbol}</Typography>
            <Typography>
              {formatTokensToReadableValue({
                value: data.toRaise.dividedBy(data.distributionAmount),
                token: TOKENS.depositToken,
                minimizeDecimals: 6,
              })}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Total Commited</Typography>
            <Typography>
              {formatTokensToReadableValue({
                value: data.totalRaised.div(1e18),
                token: TOKENS.depositToken,
                minimizeDecimals: 2,
              })}
            </Typography>
          </Box>

          {(isActive || hasEnded || data?.userInfo?.discount.gt(0)) && (
            <Box paddingY={2}>
              <Divider />
            </Box>
          )}

          {data?.userInfo && data.userInfo.discount.gt(0) && (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography>Discount Applicable</Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <InfoIcon
                  tooltip={`${formatToReadablePercentage(
                    data.userInfo.discount,
                  )} discount available to you upto MAX deposit of ${formatTokensToReadableValue({
                    value: data.userInfo.discountEligibleAmount.div(1e18),
                    token: TOKENS.depositToken,
                    minimizeDecimals: 2,
                  })}`}
                />
                <Typography>
                  {data.userInfo.discount.toString()} /{' '}
                  {data.userInfo.discountEligibleAmount.div(1e18).toString()}
                </Typography>
              </Box>
            </Box>
          )}

          {(isActive || hasEnded) && (
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography>Your Contribution</Typography>
                <Typography>
                  {!data.userInfo
                    ? '---'
                    : formatTokensToReadableValue({
                        value: data.userInfo.contribution.div(1e18),
                        token: TOKENS.depositToken,
                        minimizeDecimals: 2,
                      })}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography>Your Share</Typography>
                <Typography>
                  {!data.userInfo
                    ? '---'
                    : formatToReadablePercentage(
                        data.userInfo.contribution.eq(0)
                          ? data.userInfo.contribution
                          : data.userInfo.contribution.div(data.totalRaised).multipliedBy(100),
                      )}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography>Amount Claimable</Typography>
                <Typography>
                  {!data.userInfo
                    ? '---'
                    : formatTokensToReadableValue({
                        value: data.userInfo.claimAmount.eq(0)
                          ? data.userInfo.claimAmount
                          : data.userInfo.claimAmount.div(1e18),
                        token: TOKENS.rewardToken,
                        minimizeDecimals: 2,
                      })}
                </Typography>
              </Box>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
