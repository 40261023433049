import { BscChainId } from 'types';

import brand from 'config/brand';

export const BLOCK_TIME_MS = 3000;
// 20 blocks a minute, 60 minutes an hour, 24 hours a day
export const BLOCKS_PER_DAY = (60 / (BLOCK_TIME_MS / 1000)) * 60 * 24;
// 20 blocks a minute, 60 minutes an hour, 24 hours a day , 365 day a year
export const BLOCKS_PER_YEAR = (60 / (BLOCK_TIME_MS / 1000)) * 60 * 24 * 365;

// #pending
export const BSC_SCAN_URLS = {
  [BscChainId.MAINNET]: brand.explorer,
  [BscChainId.TESTNET]: brand.explorerTestnet,
};
