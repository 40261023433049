import { MutationObserverOptions, useMutation } from 'react-query';

import queryClient from 'clients/api/queryClient';
import { useIfoContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { useAuth } from 'context/AuthContext';

import deposit, { DepositToIfoInput, DepositToIfoOutput } from '.';

const depositToIfo = (
  options?: MutationObserverOptions<
    DepositToIfoOutput,
    Error,
    Omit<DepositToIfoInput, 'ifoContract'>
  >,
) => {
  const { accountAddress } = useAuth();
  const ifoContract = useIfoContract();

  return useMutation([FunctionKey.DEPOSIT_TO_IFO], params => deposit({ ifoContract, ...params }), {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      queryClient.invalidateQueries([FunctionKey.GET_IFO_DETAILS, accountAddress]);

      if (options?.onSuccess) {
        options.onSuccess(...onSuccessParams);
      }
    },
  });
};

export default depositToIfo;
