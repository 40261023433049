import stableCoinLogoUrl from 'assets/img/tokens/dsd.svg';
import rewardTokenLogoUrl from 'assets/img/tokens/ltoken.svg';
import logoUrl from 'assets/img/venusLogoPure.svg';
import logoUrlWithText from 'assets/img/venusLogoWithText.svg';

import type { Brands, IGlobalData } from './types';

const config: Record<Brands, IGlobalData> = {
  LSLEND: {
    name: 'LsLend',
    chainId: 1,
    chainName: 'Ethereum',
    currencyName: 'Ethereum',
    currencySymbol: 'ETH',
    stableCoinSymbol: 'DSD',
    decimals: 18,
    explorerName: 'etherscan.io',
    explorer: 'https://etherscan.io',
    explorerTestnet: 'https://goerli.etherscan.io',
    stableCoinLogoUrl,
    logoUrlWithText,
    rewardTokenLogoUrl,
    rewardTokenSymbol: 'LTOKEN',
    rewardTokenDecimals: 18,
    escrowTokenSymbol: 'esLTOKEN',
    escrowTokenLogoUrl: rewardTokenLogoUrl,
    escrowTokenDecimals: 18,
    logoUrl,
    social: {
      discord: 'https://discord.com',
      twitter: 'https://twitter.com',
    },
  },
  VENUS: {
    name: 'Venus',
    chainId: 97,
    chainName: 'Binance',
    currencyName: 'Binance Token',
    currencySymbol: 'BNB',
    stableCoinSymbol: 'DSD',
    decimals: 18,
    explorerName: 'bscscan.com',
    explorer: 'https://bscscan.com',
    explorerTestnet: 'https://testnet.bscscan.com',
    stableCoinLogoUrl, // replace these if chosen to use this config
    logoUrlWithText,
    rewardTokenLogoUrl,
    rewardTokenSymbol: 'LTOKEN',
    rewardTokenDecimals: 18,
    escrowTokenSymbol: 'esLTOKEN',
    escrowTokenLogoUrl: rewardTokenLogoUrl,
    escrowTokenDecimals: 18,
    logoUrl,
    social: {
      discord: 'https://discord.com',
      twitter: 'https://twitter.com',
    },
  },
};

export default config[(process.env.REACT_APP_BRAND_NAME as Brands) || 'LSLEND'] as IGlobalData;
