import { VError, formatVErrorToReadableString } from 'errors';
import { ContractReceipt } from 'ethers';

import { toast } from 'components/Toast';
import useSuccessfulTransactionModal, {
  OpenSuccessfulTransactionModalInput,
} from 'hooks/useSuccessfulTransactionModal';

export interface HandleMutationInput {
  mutate: () => Promise<ContractReceipt | void>;
  successTransactionModalProps: (
    contractReceipt: ContractReceipt,
  ) => OpenSuccessfulTransactionModalInput;
}

const useHandleTransactionMutation = () => {
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const handleMutation = async ({ mutate, successTransactionModalProps }: HandleMutationInput) => {
    try {
      // Send request
      const contractReceipt = await mutate();

      // Display successful transaction modal
      if (contractReceipt) {
        const successfulTransactionModalProps = successTransactionModalProps(contractReceipt);
        openSuccessfulTransactionModal(successfulTransactionModalProps);
      }
    } catch (error) {
      let { message } = error as Error;

      if (error instanceof VError) {
        message = formatVErrorToReadableString(error);
      }

      const matches = message.matchAll(/'/g);

      const found: number[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const { index } of matches) {
        if (index !== undefined) {
          found.push(index);
          if (found.length === 2) break;
        }
      }

      if (found.length === 2) {
        const [start, end] = found;
        message = message.slice(start + 1, end);
      }

      const colonIndex = message.indexOf(':');
      if (colonIndex !== -1) {
        message = message.slice(colonIndex + 1);
      }

      message = message[0].toUpperCase() + message.slice(1);

      toast.error({
        message,
      });
    }
  };

  return handleMutation;
};

export default useHandleTransactionMutation;
