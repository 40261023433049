import { BscChainId } from 'types';

// #pending - api_endpoints
export const API_ENDPOINT_URLS = {
  [BscChainId.MAINNET]: process.env.isForked
    ? 'http://localhost:3001'
    : 'https://ls-staging-api.fmobuild.com',
  [BscChainId.TESTNET]: 'https://testnetapi.venus.io/api',
};

export const RPC_URLS: {
  [key: string]: string[];
} = {
  [BscChainId.MAINNET]: [
    // 'https://bsc-dataseed.binance.org',
    // 'https://bsc-dataseed1.ninicoin.io',
    // 'https://bsc-dataseed1.defibit.io',
    // process.env.REACT_APP_MAINNET_RPC_URL ?? 'https://bsc-dataseed.binance.org', // ? forked network or mainnet rpc
    process.env.REACT_APP_MAINNET_RPC_URL ?? 'https://r2.fmobuild.com', // ? forked network or mainnet rpc
    'https://r2.fmobuild.com',
  ],
  [BscChainId.TESTNET]: ['https://bsc-testnet.nodereal.io/v1/f9777f42cc9243f0a766937df1c6a5f3'],
};
