import React from 'react';
import { Switch as RRSwitch, Redirect, Route } from 'react-router-dom';
import { isFeatureEnabled } from 'utilities';

import 'assets/styles/App.scss';
import { routes } from 'constants/routing';
import Dashboard from 'pages/Dashboard';
import Faucet from 'pages/Faucet';

const Switch = () => (
  <RRSwitch>
    <Route exact path={routes.dashboard.path} component={Dashboard} />
    {!isFeatureEnabled('faucetDisabled') && (
      <Route exact path={routes.faucet.path} component={Faucet} />
    )}
    <Redirect to={routes.dashboard.path} />
  </RRSwitch>
);

export default Switch;
