import { ContractReceipt } from 'ethers';

import { Ifo } from 'types/contracts';

export interface ClaimFromIfoInput {
  ifoContract: Ifo;
}

export type ClaimFromIfoOutput = ContractReceipt;

const claimFromIfo = async ({ ifoContract }: ClaimFromIfoInput): Promise<ClaimFromIfoOutput> => {
  const transaction = await ifoContract.claim();
  return transaction.wait(1);
};

export default claimFromIfo;
